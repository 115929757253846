import algoliasearch from 'algoliasearch/lite';
import React, { useEffect } from 'react';
import { ReactCusdis } from 'react-cusdis';
import { Configure, InstantSearch } from 'react-instantsearch-dom';
import { StringParam, useQueryParam } from 'use-query-params';

import { Layout, SEO } from '../../components';
import SearchBox from '../../components/search/search-box';
import SearchResult from '../../components/search/search-result';

const SearchPage = ({
  indices = [{ name: `yso_articles`, title: `yso_articles` }],
  location,
}) => {
  const [query, setQuery] = useQueryParam('query', StringParam);
  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  );

  useEffect(() => {
    requestAnimationFrame(() => window.scrollTo(0, 0));
  }, []);

  const showResults = query && query.length > 0;
  return (
    <Layout bodyClass="standard-layout page-search">
      <SEO title="Search YOU are safe online" url="search" />
      <InstantSearch
        searchClient={searchClient}
        indexName={indices[0].name}
        stalledSearchDelay={500}
        searchState={{
          query,
        }}
        onSearchStateChange={({ query }) => setQuery(query)}
      >
        <Configure clickAnalytics />

        <section className="body-section w-full bg-primary-100 ">
          <div className="container p-6 m-auto">
            <h1 className="text-secondary">What are you looking for?</h1>
            <p className="text-primary-300 mb-4">
              Start typing and you will see the results appear below
            </p>
            <div>
              <SearchBox autoFocus />
            </div>
          </div>
        </section>
        <div className="container">
          <section className="body-section w-full min-h-screen">
            <SearchResult show={showResults} indices={indices} />
            {!showResults && (
              <div className="text-center h-full m-auto">
                Your search results will appear here
              </div>
            )}
          </section>

          <section className="body-section w-full">
            <h2>How was your search experience?</h2>
            <ReactCusdis
              attrs={{
                host: 'https://cusdis.com',
                appId: process.env.GATSBY_CUSDIS_APP_ID,
                pageId: location.pathname,
                pageTitle: 'Search',
                pageUrl: `${process.env.SITE_URL}${location.pathname}`,
              }}
            />
          </section>
        </div>
      </InstantSearch>
    </Layout>
  );
};

export default SearchPage;
